import React from 'react';
import StyledComponents from './styles';
import BaseModal from '../BaseModal';
import CloseButton from './CloseButton';
import { radioData } from './radioData';
import RadioSelectRow from '../../RadioSelectRow';
import Button from '../../Button';
import { DeclineCancelModalVariant } from '../../../sections/TotalSection';

const { ModalTitleSC, RadioSectionTitleSC, FieldSetSC, DeclineRadioContainerSC } = StyledComponents;

function doesKeyValuePairExist(arr, keyPath, value) {
  if (arr.length === 0) {
    return false;
  }

  const keys = keyPath.split('.');

  return arr.some((item) => {
    let current = item;

    for (const key of keys) {
      if (current && typeof current === 'object' && key in current) {
        current = current[key];
      } else {
        return false;
      }
    }

    return current === value;
  });
}

const RadioDeclineCancelModal = ({
  isOpen,
  onHandleCloseModal,
  translations,
  setIsDeclineCancelModalOpen,
  setVariant,
  checkedRadio,
  setCheckedRadio,
  isQuotePage = false,
}) => {
  const declineRadioObject = radioData[0];
  const cancelRadioArray = radioData.slice(1);

  const titlesText = isQuotePage
    ? {
        modalTitle: translations.declineOrCancelTitle,
        declineSubtitle: translations.declineSubtitle,
        cancelSubtitle: translations.cancelSubtitle2,
      }
    : {
        modalTitle: translations.cancelTitle,
        declineSubtitle: '',
        cancelSubtitle: translations.cancelSubtitle1,
      };

  const isCanceledRadioChecked = doesKeyValuePairExist(cancelRadioArray, 'value', checkedRadio);

  const buttonProps =
    !isQuotePage || isCanceledRadioChecked
      ? {
          variant: 'negative',
          title: 'cancel',
          label: translations.cancelButton,
        }
      : {
          variant: 'primary',
          title: 'decline',
          label: translations.declineButton,
        };

  return (
    <BaseModal isOpen={isOpen}>
      <BaseModal.Header>
        <ModalTitleSC>{titlesText.modalTitle}</ModalTitleSC>
        <CloseButton
          onClick={() => {
            setCheckedRadio('');
            onHandleCloseModal();
          }}
        />
      </BaseModal.Header>
      <BaseModal.Content>
        <FieldSetSC>
          {isQuotePage && (
            <DeclineRadioContainerSC>
              <RadioSectionTitleSC>{titlesText.declineSubtitle}</RadioSectionTitleSC>
              <RadioSelectRow
                name="declineCancel"
                value={declineRadioObject.value}
                checkedRadio={checkedRadio}
                setCheckedRadio={setCheckedRadio}
                containerClassName="mb-4"
              >
                {translations ? translations[declineRadioObject.translationRef] : declineRadioObject.value}
              </RadioSelectRow>
            </DeclineRadioContainerSC>
          )}
          <RadioSectionTitleSC>{titlesText.cancelSubtitle}</RadioSectionTitleSC>
          {cancelRadioArray.map((radio) => (
            <RadioSelectRow
              key={radio.id}
              name={radio.name}
              value={radio.value}
              checkedRadio={checkedRadio}
              setCheckedRadio={setCheckedRadio}
            >
              {translations ? translations[radio.translationRef] : radio.value}
            </RadioSelectRow>
          ))}
        </FieldSetSC>
        <Button
          variant={buttonProps.variant}
          disabled={checkedRadio === ''}
          onClick={() => {
            onHandleCloseModal();
            setVariant(isCanceledRadioChecked ? DeclineCancelModalVariant.Cancel : DeclineCancelModalVariant.Decline);
            setIsDeclineCancelModalOpen();
          }}
          title={buttonProps.title}
        >
          {buttonProps.label}
        </Button>
      </BaseModal.Content>
    </BaseModal>
  );
};

export default RadioDeclineCancelModal;
