import styled from 'styled-components';

const ModalTitleSC = styled.h3`
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.333;
  margin: 0;
`;

const RadioSectionTitleSC = styled.h4`
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 8px;
`;

const FieldSetSC = styled.fieldset`
  width: 100%;
  border: none;
  margin: 0 0 16px;
  padding: 0;
`;

const DeclineRadioContainerSC = styled.div`
  margin-bottom: 16px;
`;

export default {
  ModalTitleSC,
  RadioSectionTitleSC,
  FieldSetSC,
  DeclineRadioContainerSC,
};
