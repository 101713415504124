import React from 'react';
import StyledComponents from './styles';

const { BaseModalContentSC } = StyledComponents;

const ModalContent = ({ children }) => {
  return <BaseModalContentSC>{children}</BaseModalContentSC>;
};

export default ModalContent;
