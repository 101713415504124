import React, { useState, useEffect } from 'react';

const useModalDelayClose = (isOpen, delayTime = 300) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    let timeoutId;
    if (!isOpen) {
      timeoutId = setTimeout(() => setIsVisible(false), delayTime); // Delay removal by the duration of the slide-out animation
    } else {
      setIsVisible(true);
    }

    return () => clearTimeout(timeoutId);
  }, [isOpen, delayTime]);

  return isVisible;
};

export default useModalDelayClose;
