import React from 'react';
import StyledComponents from './styles';

const { ButtonSC } = StyledComponents;

/**
 * Reusable button component
 * @param {{
 *   children: import('react').ReactNode;
 *   variant: 'primary' | 'negative' | 'grey';
 *   size: 'medium' | 'large';
 *   disabled: boolean;
 *   onClick(): void;
 * }} props
 */
export default function Button({ children, variant = 'primary', size = 'medium', disabled, onClick, ...rest }) {
  return (
    <ButtonSC variant={variant} size={size} disabled={disabled} onClick={onClick} {...rest} aria-disabled={disabled}>
      {children}
    </ButtonSC>
  );
}
