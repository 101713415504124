import React, { Fragment, useState } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

import Concepts from '../../components/Concepts';
import LaunchProject from '../../components/LaunchProjectContainer';
import DetailsComponent from '../../components/DetailsComponent';
import RadioDeclineCancelModal from '../../components/Modal/RadioDeclineCancelModal';
import DeclineCancelModal from '../../components/Modal/DeclineCancelModal';
import Button from '../../components/Button';
import CancelSection from '../../components/CancelSection';
import { postCancelProject } from '../../helpers/post';

const { H1 } = commonStyles;

const { Sticky, TotalContainer, TotalDivider, ProjectCanceldButtonSC, LargeButtonTextColorOverwriteSC } =
  styledComponents;

export const DeclineCancelModalVariant = Object.freeze({
  Decline: 'decline',
  Cancel: 'cancel',
});

export default function TotalSection({
  fetchedRequestData,
  browserLanguage,
  requestId,
  translations,
  isCanceled,
  setIsProjectCanceled,
  isRadioDeclineCancelModalOpen,
  setIsRadioDeclineCancelModalOpen,
  isProjectLaunched,
}) {
  const [checkedRadio, setCheckedRadio] = useState('');
  const [isDeclineCancelModalOpen, setIsDeclineCancelModalOpen] = useState(false);
  const [variant, setVariant] = useState(DeclineCancelModalVariant.Cancel);
  const [isActionPending, setIsActionPending] = useState(false);

  let depositExists =
    fetchedRequestData &&
    fetchedRequestData.Pricing &&
    fetchedRequestData.Pricing.Deposit &&
    fetchedRequestData.Pricing.Deposit.Amount;

  const handleCancelProject = async () => {
    setIsActionPending(true);
    await postCancelProject(fetchedRequestData.Request.Id, checkedRadio);
    setIsDeclineCancelModalOpen(false);
    setIsProjectCanceled(true);
  };

  return (
    <Fragment>
      <Sticky>
        <DetailsComponent
          fetchedRequestData={fetchedRequestData}
          browserLanguage={browserLanguage}
          translations={translations}
        />
        <TotalContainer>
          <H1>{translations.totalSection.total.title}</H1>
          {/*  Concept Common Component */}
          <Concepts fetchedRequestData={fetchedRequestData} translations={translations} />
          <TotalDivider />
          {isCanceled ? (
            <ProjectCanceldButtonSC>
              <Button variant={'grey'} size={'large'}>
                <LargeButtonTextColorOverwriteSC>
                  {translations.totalSection.total.cancel.projectCanceledButton}
                </LargeButtonTextColorOverwriteSC>
              </Button>
            </ProjectCanceldButtonSC>
          ) : (
            <LaunchProject fetchedRequestData={fetchedRequestData} requestId={requestId} translations={translations} />
          )}
        </TotalContainer>
        {!isCanceled && !isProjectLaunched && (
          <CancelSection
            translations={translations.totalSection.total.cancel}
            handleOnClick={() => setIsRadioDeclineCancelModalOpen(true)}
          />
        )}
      </Sticky>
      <RadioDeclineCancelModal
        isOpen={isRadioDeclineCancelModalOpen}
        onHandleCloseModal={() => setIsRadioDeclineCancelModalOpen(false)}
        translations={translations.radioDeclineCancelModal}
        setIsDeclineCancelModalOpen={() => setIsDeclineCancelModalOpen(true)}
        setVariant={setVariant}
        checkedRadio={checkedRadio}
        setCheckedRadio={setCheckedRadio}
      />
      <DeclineCancelModal
        isOpen={isDeclineCancelModalOpen}
        variant={variant}
        onHandleCloseModal={() => setIsDeclineCancelModalOpen(false)}
        onHandleClickCancel={handleCancelProject}
        disableButton={isActionPending}
        hasDeposit={!!depositExists}
        setCheckedRadio={setCheckedRadio}
        translations={translations.declineCancelModal}
      />
    </Fragment>
  );
}
