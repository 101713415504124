import React, { useState } from 'react';
import styledComponents from './styles';
import RadioDeclineCancelModal from '../../../UpfrontPricePage/components/Modal/RadioDeclineCancelModal';
import DeclineCancelModal from '../../../UpfrontPricePage/components/Modal/DeclineCancelModal';
import { DeclineCancelModalVariant } from '../../../UpfrontPricePage/sections/TotalSection';
import { postCancelProject } from '../../../UpfrontPricePage/helpers/post';
import { declineQuote } from '../../helpers/post';

const { LaunchProjectContainer, ApproveQuoteButton, DeclineQuoteButton } = styledComponents;

export default function ApproveDeclineQuote({
  acceptBidByGuid,
  handleContinue,
  translations,
  financingEnabled,
  setIsProjectCanceled,
  setIsQuoteDeclined,
  fetchedBidData,
  guid,
  estimateTranslations,
}) {
  const [isRadioDeclineCancelModalOpen, setIsRadioDeclineCancelModalOpen] = useState(false);
  const [checkedRadio, setCheckedRadio] = useState('');
  const [isDeclineCancelModalOpen, setIsDeclineCancelModalOpen] = useState(false);
  const [variant, setVariant] = useState(DeclineCancelModalVariant.Decline);
  const [isActionPending, setIsActionPending] = useState(false);

  let depositExists =
    fetchedBidData && fetchedBidData.Pricing && fetchedBidData.Pricing.Deposit && fetchedBidData.Pricing.Deposit.Amount;

  const handleCancelProject = async () => {
    setIsActionPending(true);
    await postCancelProject(fetchedBidData.Request.Id, checkedRadio);
    setIsDeclineCancelModalOpen(false);
    setIsProjectCanceled(true);
  };

  const handleDeclineQuote = async () => {
    setIsActionPending(true);
    await declineQuote(guid);
    setIsDeclineCancelModalOpen(false);
    setIsQuoteDeclined(true);
  };

  return (
    <LaunchProjectContainer>
      {financingEnabled ? (
        <ApproveQuoteButton type="button" onClick={handleContinue}>
          Continue...
        </ApproveQuoteButton>
      ) : (
        <ApproveQuoteButton type="button" onClick={acceptBidByGuid}>
          {translations.totalSection.total.approveDeclineQuote.buttonApprove}
        </ApproveQuoteButton>
      )}
      <DeclineQuoteButton type="button" onClick={() => setIsRadioDeclineCancelModalOpen(true)}>
        {translations.totalSection.total.approveDeclineQuote.buttonDecline}
      </DeclineQuoteButton>
      <RadioDeclineCancelModal
        isOpen={isRadioDeclineCancelModalOpen}
        onHandleCloseModal={() => setIsRadioDeclineCancelModalOpen(false)}
        translations={estimateTranslations.radioDeclineCancelModal}
        setIsDeclineCancelModalOpen={() => setIsDeclineCancelModalOpen(true)}
        setVariant={setVariant}
        checkedRadio={checkedRadio}
        setCheckedRadio={setCheckedRadio}
        isQuotePage={true}
      />
      <DeclineCancelModal
        isOpen={isDeclineCancelModalOpen}
        variant={variant}
        onHandleCloseModal={() => setIsDeclineCancelModalOpen(false)}
        onHandleClickDecline={handleDeclineQuote}
        onHandleClickCancel={handleCancelProject}
        disableButton={isActionPending}
        hasDeposit={!!depositExists}
        setCheckedRadio={setCheckedRadio}
        translations={estimateTranslations.declineCancelModal}
      />
    </LaunchProjectContainer>
  );
}
