import styled from 'styled-components';

const ModalCloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: none;
  background: transparent;
  cursor: pointer;
`;

export default {
  ModalCloseButton,
};
