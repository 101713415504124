const fetchData = async (endpoint, transformData) => {
  const url = `${window.env.qmtUrl}/${endpoint}`;

  const settings = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Subscription-Key': window.env.apiKey,
    },
  };

  try {
    const response = await fetch(url, settings);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(JSON.stringify(errorData) || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    // Apply the data transformation if provided
    const transformedData = transformData ? transformData(data) : data;

    return {
      message: 'Success',
      data: transformedData,
      status: response.status,
    };
  } catch (error) {
    console.error(`Error fetching ${endpoint}:`, error);
    throw error;
  }
};

export const getRequestByGuid = async (requestId) => {
  try {
    return await fetchData(`Estimate/${requestId}`);
  } catch (error) {
    console.error('Error in getRequestByGuid:', error);
    throw error;
  }
};
