import React from 'react';

const RadioIcon = ({ isChecked, isHovered }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {!isChecked && (
        <path
          d="M16.5 10C16.5 7.6875 15.25 5.5625 13.25 4.375C11.2188 3.21875 8.75 3.21875 6.75 4.375C4.71875 5.5625 3.5 7.6875 3.5 10C3.5 12.3438 4.71875 14.4688 6.75 15.6562C8.75 16.8125 11.2188 16.8125 13.25 15.6562C15.25 14.4688 16.5 12.3438 16.5 10ZM2 10C2 7.15625 3.5 4.53125 6 3.09375C8.46875 1.65625 11.5 1.65625 14 3.09375C16.4688 4.53125 18 7.15625 18 10C18 12.875 16.4688 15.5 14 16.9375C11.5 18.375 8.46875 18.375 6 16.9375C3.5 15.5 2 12.875 2 10Z"
          fill={isHovered ? '#2F55FE' : '#898E9E'}
        />
      )}
      {isChecked && (
        <path
          d="M10 18C7.125 18 4.5 16.5 3.0625 14C1.625 11.5312 1.625 8.5 3.0625 6C4.5 3.53125 7.125 2 10 2C12.8438 2 15.4688 3.53125 16.9062 6C18.3438 8.5 18.3438 11.5312 16.9062 14C15.4688 16.5 12.8438 18 10 18ZM10 7C8.90625 7 7.9375 7.59375 7.375 8.5C6.84375 9.4375 6.84375 10.5938 7.375 11.5C7.9375 12.4375 8.90625 13 10 13C11.0625 13 12.0312 12.4375 12.5938 11.5C13.125 10.5938 13.125 9.4375 12.5938 8.5C12.0312 7.59375 11.0625 7 10 7Z"
          fill="#2F55FE"
        />
      )}
    </svg>
  );
};

export default RadioIcon;
