import React from 'react';
import StyledComponents from './styles';
import BaseModal from '../BaseModal';
import Button from '../../Button';
import { DeclineCancelModalVariant } from '../../../sections/TotalSection';

const { ContentContainerSC, FontAwesomeSC, ModalTitleSC, TextBodyMedSC, ButtonMenuSC, FontAwesomeSpinnerSC } =
  StyledComponents;

const DeclineCancelModal = ({
  isOpen,
  variant,
  onHandleCloseModal,
  onHandleClickDecline,
  onHandleClickCancel,
  disableButton = false,
  hasDeposit = false,
  setCheckedRadio,
  translations,
}) => {
  const isDecline = variant === DeclineCancelModalVariant.Decline;

  const renderActionButtonText = () => {
    if (disableButton) {
      return <FontAwesomeSpinnerSC className="far fa-spinner-third fa-spin fa-lg" />;
    }

    return isDecline ? translations.declineQuote : translations.cancelProject;
  };

  return (
    <BaseModal isOpen={isOpen}>
      <BaseModal.Content>
        <ContentContainerSC>
          <FontAwesomeSC className="fal fa-exclamation-triangle" />
          <ModalTitleSC>{isDecline ? translations.declineTitle : translations.cancelTitle}</ModalTitleSC>
          {!isDecline && <TextBodyMedSC>{translations.cannotBeUndone}</TextBodyMedSC>}
          {hasDeposit && !isDecline && <TextBodyMedSC>{translations.youCanExpect}</TextBodyMedSC>}
        </ContentContainerSC>
        <ButtonMenuSC>
          <Button
            variant="secondary"
            onClick={() => {
              onHandleCloseModal();
              setCheckedRadio('');
            }}
            disabled={disableButton}
            title={'reset'}
            isSideBySide={true}
          >
            {translations.cancel}
          </Button>
          <Button
            variant={isDecline ? 'primary' : 'negative'}
            onClick={isDecline ? onHandleClickDecline : onHandleClickCancel}
            disabled={disableButton}
            title={isDecline ? 'final-decline' : 'final-cancel'}
            isSideBySide={true}
          >
            {renderActionButtonText()}
          </Button>
        </ButtonMenuSC>
      </BaseModal.Content>
    </BaseModal>
  );
};

export default DeclineCancelModal;
