import React, { useState, useEffect } from 'react';
import Loader from '../../components/Loader';
import formatter from '../../lib/formatter';
import _ from 'lodash';
import queryString from 'query-string';

import StyledComponents from './styles';
import commonStyles from '../../components/DesignPatternV2.0/commonStyles';

import TimelineTracker from './components/TimelineTracker';
import StatusScreen from './components/StatusScreen';
import ProjectDetailsWrapper from './components/ProjectDetails';
import Modal from './components/Modal';
import { filterForActiveBid } from './helpers/BidsDates';
import useDelayModalUnmount from '../UpfrontPricePage/components/Modal/hook';

import translationsLocalData from '../../../server/locale/en_US.json';

import { getInstalledOnDate, getAppointmentDates } from './helpers/InstallationsDates';

import getRequestStatus from './helpers/Status';

const { P } = commonStyles;

const {
  MainWrapper,
  LogoWrapper,
  InfoWrapper,
  StepsWrapper,
  Divider,
  Footer,
  FooterDivider,
  QmeritLogo,
  Assistance,
  QmeritContactInfo,
  FooterContainer,
  GlobalTrackerStyles,
} = StyledComponents;

export default function ProjectStatusTracker(props) {
  const toggleMountModal = () => {
    setIsMounted(!isMounted);
  };

  //Getting Request Guid parsing token from the URL
  const getRequestGuid = (url) => {
    return queryString.parse(url).token;
  };

  //Getting Request data using the Guis previously obtained
  const getRequestByGuid = (requestGuid) => {
    let optionalParams = {
      headers: { 'content-type': 'application/json; charset=UTF-8', 'Subscription-Key': `${window.env.apiKey}` },
      method: 'GET',
    };

    return new Promise((resolve, reject) => {
      fetch(`${window.env.qmtUrl}/ProjectStatusTracker/${requestGuid}`, optionalParams)
        .then((response) => {
          setFetchedBidStatus(response.status);
          return response.json();
        })
        .then((data) => {
          setFetchedRequestData(data);
          setIsLoading(false);
          const activeBid = filterForActiveBid(data.Bids);
          activeBid[0].NeedsReview == true ? setIsMounted(true) : setIsMounted(false);
        })
        .catch((error) => {
          reject({
            isLoading: false,
            fetchedData: { ErrorCode: 500, ...error },
          });
        });
    });
  };

  const setCanceledRequest = () => {
    setIsCanceled(true);
  };

  const [translations, setTranslations] = useState({});
  const [fetchedRequestData, setFetchedRequestData] = useState([]);
  const [fetchedBidStatus, setFetchedBidStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [browserLanguage, setBrowserLanguage] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const modalOpen = useDelayModalUnmount(isMounted, 300);

  useEffect(() => {
    _.has(window, 'env.translations') && !_.isEmpty(window.env.translations)
      ? setTranslations(window.env.translations)
      : setTranslations(translationsLocalData);

    let requestGuid = getRequestGuid(props.location.search);

    fetch('/language', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((data) => setBrowserLanguage(data.browserLanguage));

    if (!_.isEmpty(requestGuid)) {
      getRequestByGuid(requestGuid);
    }
  }, [isCanceled]);

  if (fetchedBidStatus == 200 && !isLoading) {
    var timeZoneT = fetchedRequestData.TimeZone.timeZoneId;

    //Here, we're formatting the desired dates so we can use them in the desired format in our UI
    const submittedOnDate = formatter.getIntlFormattedDateTime(
      fetchedRequestData.Survey.SubmittedOn,
      browserLanguage,
      timeZoneT
    );
    const installedOnDate = formatter.getIntlFormattedDateTime(
      getInstalledOnDate(fetchedRequestData),
      browserLanguage,
      timeZoneT
    );
    const appointmentDateStart = formatter.getIntlFormattedDateTime(
      getAppointmentDates(fetchedRequestData, 'start'),
      browserLanguage,
      timeZoneT
    );
    const appointmentDateEnd = formatter.getIntlFormattedDateTime(
      getAppointmentDates(fetchedRequestData, 'end'),
      browserLanguage,
      timeZoneT
    );

    //Update: We now get the current status from an external helper function
    let currentStatus = getRequestStatus(fetchedRequestData, translations.projectStatusTracker.bannerSection);

    let isRequestNotCanceled = fetchedRequestData.Status !== 'Canceled';

    return (
      <React.Fragment>
        <GlobalTrackerStyles canceled={fetchedRequestData.Status == 'Canceled'} />
        {modalOpen && isRequestNotCanceled && (
          <Modal isMounted={isMounted} toggleMountModal={toggleMountModal} title={'Quote unavailable'}>
            <P style={{ textAlign: 'justify' }}>
              This quote was revised by your assigned certified installer and now requires further review. Once this has
              been approved, you will be notified for final review and approval.
            </P>
          </Modal>
        )}
        <LogoWrapper>
          <QmeritLogo src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-White.png`} />
        </LogoWrapper>
        <MainWrapper>
          <TimelineTracker
            currentStatus={currentStatus}
            submittedOnDate={submittedOnDate}
            appointmentDateStart={appointmentDateStart}
            appointmentDateEnd={appointmentDateEnd}
            installedOnDate={installedOnDate}
            fetchedRequestData={fetchedRequestData}
            translations={translations.projectStatusTracker.bannerSection}
          />
          <InfoWrapper>
            <StepsWrapper>
              <StatusScreen
                requestData={fetchedRequestData}
                status={currentStatus}
                browserLanguage={browserLanguage}
                appointmentDateStart={appointmentDateStart}
                appointmentDateEnd={appointmentDateEnd}
                installedOnDate={installedOnDate}
                translations={translations.projectStatusTracker}
              />
            </StepsWrapper>
            <Divider />
            <ProjectDetailsWrapper
              requestData={fetchedRequestData}
              translations={translations.projectStatusTracker}
              currentStatus={currentStatus}
              radioModalTranslations={translations.estimatePage.radioDeclineCancelModal}
              cancelModalTranslations={translations.estimatePage.declineCancelModal}
              setCanceledRequest={setCanceledRequest}
            />
          </InfoWrapper>
          <FooterDivider />
          <FooterContainer>
            <Footer>
              <Assistance>{translations.projectStatusTracker.general.assistance}</Assistance>
              <QmeritContactInfo href={`tel:${fetchedRequestData.CustomerServiceInfo.CustomerServicePhone}`}>
                {fetchedRequestData.CustomerServiceInfo.CustomerServicePhone}
              </QmeritContactInfo>
              <QmeritContactInfo href={`mailto:${fetchedRequestData.CustomerServiceInfo.CustomerServiceEmail}`}>
                {fetchedRequestData.CustomerServiceInfo.CustomerServiceEmail}
              </QmeritContactInfo>
            </Footer>
          </FooterContainer>
        </MainWrapper>
        <div id={'modal-portal'} />
      </React.Fragment>
    );
  }

  return <Loader color={'#000000'} isLoading={isLoading} />;
}
