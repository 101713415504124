import styled from 'styled-components';

const DeclineCancelButtonSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
  pointer-events: none;
`;

const LargeButtonTextColorOverwriteSC = styled.span`
  color: #3c3e49;
`;

export default {
  DeclineCancelButtonSC,
  LargeButtonTextColorOverwriteSC,
};
