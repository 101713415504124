import styled from 'styled-components';

const LabelSC = styled.label`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 12px 8px;
  cursor: pointer;
  &:hover:not(:has(input:checked)) {
    border-radius: 8px;
    background: #f6f7f8;
  }
  &:has(input:checked) {
    border-radius: 8px;
    background: #f0f3ff;
  }
`;

const RadioInputSC = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  left: -9999px;
  width: 20px;
  height: 20px;
  opacity: 0.00001;
  &:focus + svg {
    outline: 1px solid #000;
  }
  &:focus:not(:focus-visible) + svg {
    outline: none;
  }
`;

const SpanSC = styled.span`
  font-family: 'Mulish', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  max-width: 299px;
`;

export default {
  LabelSC,
  RadioInputSC,
  SpanSC,
};
