import React from 'react';
import styledComponents from './styles';
import Button from '../../../UpfrontPricePage/components/Button';

const { DeclineCancelButtonSC, LargeButtonTextColorOverwriteSC } = styledComponents;

/**
 * For visual representation of a declined quote or canceled project
 * @param {string} label - The label of the button
 * @returns {ReactNode}
 */
export default function DeclineCancelButton({ label }) {
  return (
    <DeclineCancelButtonSC>
      <Button variant={'grey'} size={'large'}>
        <LargeButtonTextColorOverwriteSC>{label}</LargeButtonTextColorOverwriteSC>
      </Button>
    </DeclineCancelButtonSC>
  );
}
