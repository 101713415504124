import React from 'react';
import styledComponents from './styles';

const { CancelSectionSC, CancelSpanSC, CancelButtonSC } = styledComponents;

export default function CancelSection({ translations, handleOnClick }) {
  return (
    <CancelSectionSC>
      <CancelSpanSC>
        {translations.message}{' '}
        <CancelButtonSC onClick={handleOnClick}>
          <u>{translations.cancelButton}</u>
        </CancelButtonSC>
      </CancelSpanSC>
    </CancelSectionSC>
  );
}
