export const postCancelProject = async (requestId, checkedRadio, reason = '') => {
  const url = `${window.env.qmtUrl}/Request/${requestId}/Cancel`;

  let settings = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Subscription-Key': window.env.apiKey,
    },
    body: JSON.stringify({
      SelectedOption: checkedRadio,
      Reason: reason,
      CloseProject: true,
    }),
  };

  try {
    const response = await fetch(url, settings);

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  } catch (error) {
    console.log('Something went wrong: ', error);
    throw error;
  }
};
