import React, { useState } from 'react';
import _ from 'lodash';
import StyledComponents from './styles';
import RadioIcon from './radio-icon';

const { LabelSC, RadioInputSC, SpanSC } = StyledComponents;

/**
 * A single radio button row component with hover styling and accessibility features.
 * This component renders a radio input, a custom radio icon, and a label.  It manages its own hover state and
 * connects to a parent component to manage the overall checked state.
 *
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.name - The name of the radio button group.  All radio buttons in the same group should share the same name.  This is crucial for proper radio button behavior.
 * @param {string} props.value - The value of this specific radio button. This value is submitted when the radio button is selected.
 * @param {string} props.checkedRadio - The currently checked value within the radio button group. This should be managed by a parent component to control the overall state.
 * @param {function} props.setCheckedRadio - A callback function provided by the parent component to update the `checkedRadio` state.  This function is called when this radio button is selected.
 * @param {React.ReactNode} [props.children] - Optional content to display next to the radio button. If not provided, the `value` prop will be used as the label.
 * @returns {JSX.Element} - The radio button row element.
 *
 * @example
 * // In a parent component:
 * const [checkedRadio, setCheckedRadio] = useState('apple');
 *
 * <fieldset>
 *  <RadioSelectRow name="fruit" value="apple" checkedRadio={checkedRadio} setCheckedRadio={setCheckedRadio}>Apple</RadioSelectRow>
 *  <RadioSelectRow name="fruit" value="banana" checkedRadio={checkedRadio} setCheckedRadio={setCheckedRadio}>Banana</RadioSelectRow>
 *  <RadioSelectRow name="fruit" value="orange" checkedRadio={checkedRadio} setCheckedRadio={setCheckedRadio} /> // Value will be used as label
 * </fieldset>
 */
const RadioSelectRow = ({ name, value, checkedRadio, setCheckedRadio, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const uniqueId = _.uniqueId('radio-');

  const radioHandler = (e) => {
    setCheckedRadio(e.target.value);
  };

  const isChecked = value === checkedRadio;

  return (
    <LabelSC htmlFor={uniqueId} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <RadioInputSC type="radio" id={uniqueId} name={name} value={value} onChange={radioHandler} checked={isChecked} />
      <RadioIcon isChecked={isChecked} isHovered={isHovered} />
      <SpanSC>{children ?? value}</SpanSC>
    </LabelSC>
  );
};

export default RadioSelectRow;
