import styled from 'styled-components';

const CancelSectionSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const CancelSpanSC = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.14;
  margin: 0px;
`;

const CancelButtonSC = styled.button`
  border: none;
  outline: none;
  background: none;
  color: #3e4148;
  cursor: pointer;
`;

export default {
  CancelSectionSC,
  CancelSpanSC,
  CancelButtonSC,
};
