import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import StyledComponents from './styles';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import useModalDelayClose from './hook/useModalDelayClose';

const { ModalBackground, ScrollDisabler, BaseModalContainerSC } = StyledComponents;

/**
 * A reusable modal component.
 * This component uses a portal to render the modal content outside of the normal document flow,
 * typically appended to the `body` element.  This helps to avoid z-index and overflow issues.
 * It also includes a background overlay that can be clicked to close the modal.
 *
 * @component
 * @param {object} props - The component's props.
 * @param {boolean} props.isOpen - Controls whether the modal is open or closed.
 * @param {React.ReactNode} props.children - The content to be displayed within the modal.
 * @param {function} [props.onHandleClose] - A callback function that is called when the modal's background is clicked.
 *                                       This is typically used to close the modal.
 * @returns {JSX.Element|null} - The modal element if `isVisible` is true, otherwise null.
 */
const BaseModal = ({ isOpen, children, onHandleClose = null }) => {
  /**
   * Controls the visibility of the modal, incorporating a delay for closing.
   * This prevents the modal from instantly disappearing when the close action is triggered,
   * allowing for smooth transitions or animations.
   * @type {boolean}
   */
  const isVisible = useModalDelayClose(isOpen);

  if (!isVisible) {
    return null;
  }

  return createPortal(
    <Fragment>
      <ModalBackground onClick={onHandleClose} animate={isOpen ? 'in' : 'out'}>
        <BaseModalContainerSC
          role="dialog"
          aria-modal="true"
          onClick={(e) => e.stopPropagation()}
          animate={isOpen ? 'in' : 'out'}
        >
          {children}
        </BaseModalContainerSC>
      </ModalBackground>
      <ScrollDisabler />
    </Fragment>,
    document.getElementById('modal-portal')
  );
};

/**
 * The header component for the modal.  This should be used as a direct child of the `BaseModal` component.
 * @component
 * @example
 * <BaseModal isOpen={true}>
 *   <BaseModal.Header>Modal Title</BaseModal.Header>
 *   <BaseModal.Content>Modal Content</BaseModal.Content>
 * </BaseModal>
 */
BaseModal.Header = ModalHeader;

/**
 * The content component for the modal. This should be used as a direct child of the `BaseModal` component.
 * @component
 * @example
 * <BaseModal isOpen={true}>
 *   <BaseModal.Header>Modal Title</BaseModal.Header>
 *   <BaseModal.Content>Modal Content</BaseModal.Content>
 * </BaseModal>
 */
BaseModal.Content = ModalContent;

export default BaseModal;
