import styled, { css } from 'styled-components';

const variants = (variant = 'primary') =>
  ({
    primary: css`
      color: #fff;
      background-color: #2f55fe;
      &:not([disabled]):hover {
        background-color: #0b38fe;
      }
      &:not([disabled]):active {
        background-color: #3e61fe;
      }
    `,
    negative: css`
      color: #fff;
      background-color: #e50000;
      &:not([disabled]):hover {
        background-color: #d10000;
      }
      &:not([disabled]):active {
        background-color: #fa0000;
      }
    `,
    grey: css`
      color: #6e727c;
      background-color: #edeeef;
      &:not([disabled]):hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
      &:not([disabled]):active {
        background-color: #dedfe2;
      }
    `,
  }[variant]);

const sizes = (size = 'medium') =>
  ({
    medium: css`
      height: 40px;
      min-width: 100px;
    `,
    large: css`
      height: 52px;
      min-width: 150px;
      width: 100%;
      font-size: 1rem;
      line-height: 1.25;
    `,
  }[size]);

const ButtonSC = styled.button`
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Mulish', sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.333;
  cursor: pointer;
  ${({ size }) => sizes(size)}
  ${({ variant }) => variants(variant)}
  ${(props) =>
    props.isSideBySide &&
    css`
      min-width: 49%;
    `}
  &:disabled {
    opacity: 40%;
    cursor: not-allowed;
  }
`;

export default {
  ButtonSC,
};
