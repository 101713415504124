import React, { useState } from 'react';
import StyledComponents from './styles';
import _ from 'lodash';

import RadioDeclineCancelModal from '../../../UpfrontPricePage/components/Modal/RadioDeclineCancelModal';
import DeclineCancelModal from '../../../UpfrontPricePage/components/Modal/DeclineCancelModal';
import CallToCancelModal from '../CallToCancelModal';

import {
  getBidServiceProviderName,
  getBidServiceProviderPhone,
  getBidServiceProviderEmail,
} from '../../helpers/BidsDates';

const { Wrapper, ProjectDetailsCard, CardTitle, DetailTitle, DetailContent, CancelButtonSC } = StyledComponents;

export const DeclineCancelModalVariant = Object.freeze({
  Decline: 'decline',
  Cancel: 'cancel',
});

export default function ProjectDetailsWrapper({
  requestData,
  translations,
  currentStatus,
  radioModalTranslations,
  cancelModalTranslations,
  setCanceledRequest,
}) {
  const [isRadioDeclineCancelModalOpen, setIsRadioDeclineCancelModalOpen] = useState(false);
  const [checkedRadio, setCheckedRadio] = useState('');
  const [isDeclineCancelModalOpen, setIsDeclineCancelModalOpen] = useState(false);
  const [variant, setVariant] = useState(DeclineCancelModalVariant.Decline);
  const [isCallToCancelModalOpen, setIsCallToCancelModalOpen] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const handleCancelProject = async () => {
    const body = {
      SelectedOption: checkedRadio,
      Reason: '',
      CloseProject: true,
    };

    let optionalParams = {
      headers: { 'content-type': 'application/json; charset=UTF-8', 'Subscription-Key': `${window.env.apiKey}` },
      body: JSON.stringify(body),
      method: 'POST',
    };

    try {
      const response = await fetch(`${window.env.qmtUrl}/Request/${requestData.RequestId}/Cancel`, optionalParams);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Project canceled successfully:', data);

      setIsDeclineCancelModalOpen(false);
      setIsCanceled(true);
      setCanceledRequest();
    } catch (error) {
      console.error('Error canceling project:', error);
    }
  };

  const callToCancelNeeded = () => {
    if (!_.isNil(requestData.Installations) && !_.isEmpty(requestData.Installations)) setIsCallToCancelModalOpen(true);
    else setIsRadioDeclineCancelModalOpen(true);
  };

  const checkForDeposit = () => {
    let depositAmount = requestData.Settlement.DepositAmount.Amount;
    let depositBalance = requestData.Settlement.DepositBalance.Amount;
    if (depositAmount > 0 && depositBalance == 0) return true;
    else return false;
  };

  let isRequestNotCanceled = requestData.Status !== 'Canceled';

  return (
    <Wrapper>
      <ProjectDetailsCard>
        <CardTitle>{translations.general.projectDetails}</CardTitle>
        <DetailTitle>{translations.general.project}</DetailTitle>
        {!_.isNil(requestData.Project) && <DetailContent>{requestData.Project.ProjectName}</DetailContent>}
        {_.isNil(requestData.Project) && `${translations.general.noSPAssigned}`}
        <DetailTitle>{translations.general.contactInformation}</DetailTitle>
        <DetailContent>
          {requestData.FirstName} {requestData.LastName}
        </DetailContent>
        <DetailContent>{requestData.Phone}</DetailContent>
        <DetailContent>{requestData.Email}</DetailContent>
        <DetailTitle>{translations.general.site}</DetailTitle>
        <DetailContent>{requestData.Street}</DetailContent>
        <DetailContent>
          {requestData.City}, {requestData.State} {requestData.ZipCode}
        </DetailContent>
        <DetailTitle>{translations.general.serviceProvider}</DetailTitle>
        {getBidServiceProviderName(requestData) &&
        currentStatus.name !== translations.bannerSection.assignment.statusName ? (
          <React.Fragment>
            <DetailContent>{getBidServiceProviderName(requestData)}</DetailContent>
            <DetailContent>{getBidServiceProviderPhone(requestData)}</DetailContent>
            <DetailContent>{getBidServiceProviderEmail(requestData)}</DetailContent>
          </React.Fragment>
        ) : (
          <DetailContent italic>{translations.general.noSPAssigned}</DetailContent>
        )}
        {!isCanceled && isRequestNotCanceled && (
          <CancelButtonSC onClick={callToCancelNeeded}>
            <u>{translations.general.cancelProject}</u>
          </CancelButtonSC>
        )}
      </ProjectDetailsCard>
      <RadioDeclineCancelModal
        isOpen={isRadioDeclineCancelModalOpen}
        onHandleCloseModal={() => setIsRadioDeclineCancelModalOpen(false)}
        translations={radioModalTranslations}
        setIsDeclineCancelModalOpen={() => setIsDeclineCancelModalOpen(true)}
        setVariant={setVariant}
        checkedRadio={checkedRadio}
        setCheckedRadio={setCheckedRadio}
      />
      <DeclineCancelModal
        isOpen={isDeclineCancelModalOpen}
        variant={variant}
        onHandleCloseModal={() => setIsDeclineCancelModalOpen(false)}
        onHandleClickCancel={handleCancelProject}
        onHandleClickDecline={null}
        // disableButton={isDeclinePending}
        hasDeposit={checkForDeposit()}
        setCheckedRadio={setCheckedRadio}
        translations={cancelModalTranslations}
        data-testid="decline-cancel-modal"
      />
      <CallToCancelModal
        isOpen={isCallToCancelModalOpen}
        onHandleCloseModal={() => setIsCallToCancelModalOpen(false)}
        translations={translations.callToCancelModal}
      />
    </Wrapper>
  );
}
