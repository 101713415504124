export const declineQuote = async (guid) => {
  const url = `${window.env.qmtUrl}/Bid/${guid}/Reject`;

  let settings = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Subscription-Key': window.env.apiKey,
    },
  };

  try {
    const response = await fetch(url, settings);

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  } catch (error) {
    console.log('Something went wrong: ', error);
    throw error;
  }
};
