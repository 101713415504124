import React from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

import Concepts from '../../components/Concepts';
import ApproveDeclineQuote from '../../components/ApproveDeclineQuote';
import DetailsComponent from '../../components/DetailsComponent';
import QuoteApprovedButton from '../../components/QuoteApprovedButton';
import TaxExemption from '../../components/TaxExemption';
import DeclineCancelButton from '../../components/DeclineCancelButton';

const { H1 } = commonStyles;

const { Sticky, TotalContainer, TotalDivider } = styledComponents;

export default function TotalSection({
  fetchedBidData,
  browserLanguage,
  bidData,
  acceptBidByGuid,
  handleContinue,
  translations,
  financingEnabled,
  isProjectCanceled,
  setIsProjectCanceled,
  isQuoteDeclined,
  setIsQuoteDeclined,
  estimateTranslations,
}) {
  const canceled = isProjectCanceled || fetchedBidData.Request.CancelledOn;
  const declined = isQuoteDeclined || bidData.Status === 'rejected';
  const approved = bidData.Status === 'confirmed';

  const renderButtons = () => {
    if (approved) {
      return <QuoteApprovedButton translations={translations.totalSection.total.QuoteApproved.button} />;
    } else if (canceled) {
      return <DeclineCancelButton label={translations.totalSection.total.ProjectCanceled.button} />;
    } else if (declined) {
      return <DeclineCancelButton label={translations.totalSection.total.QuoteDeclined.button} />;
    }

    return (
      <ApproveDeclineQuote
        acceptBidByGuid={acceptBidByGuid}
        handleContinue={handleContinue}
        translations={translations}
        financingEnabled={financingEnabled}
        setIsProjectCanceled={setIsProjectCanceled}
        setIsQuoteDeclined={setIsQuoteDeclined}
        fetchedBidData={fetchedBidData}
        guid={bidData.Id}
        estimateTranslations={estimateTranslations}
      />
    );
  };

  return (
    <Sticky>
      <DetailsComponent
        fetchedBidData={fetchedBidData}
        browserLanguage={browserLanguage}
        bidData={bidData}
        translations={translations}
      />
      <TotalContainer>
        <H1>{translations.totalSection.total.title}</H1>
        {/*  Concept Common Component */}
        <Concepts fetchedBidData={fetchedBidData} translations={translations} />
        <TotalDivider />
        {/* Tax Exemption Section */}
        {fetchedBidData.Request?.TaxExemptionContractUrl && (
          <TaxExemption
            TaxExemptionContractUrl={fetchedBidData.Request.TaxExemptionContractUrl}
            screenSize={'desktop'}
            translations={translations}
          />
        )}
        {renderButtons()}
      </TotalContainer>
    </Sticky>
  );
}
