import React from 'react';
import StyledComponents from './styles';
import BaseModal from '../../../UpfrontPricePage/components/Modal/BaseModal';
import Button from '../Button';

const { ContentContainerSC, FontAwesomeSC, ModalTitleSC, TextBodyMedSC, ButtonMenuSC, ContactAnchor, ContactTextSC } =
  StyledComponents;

const CallToCancelModal = ({ isOpen, onHandleCloseModal, disableButton = false, translations }) => {
  return (
    <BaseModal isOpen={isOpen}>
      <BaseModal.Content>
        <ContentContainerSC>
          <FontAwesomeSC className="fal fa-exclamation-triangle" data-testid="exclamation-triangle-icon" />
          <ModalTitleSC>{translations.title}</ModalTitleSC>
          <div>
            <ContactTextSC>{translations.contactLeft}</ContactTextSC>
            <ContactTextSC>
              <ContactAnchor href={`tel:${window.env.csPhone}`} data-testid="phone-link">
                {window.env.csPhone}
              </ContactAnchor>
            </ContactTextSC>
            <ContactTextSC>{translations.contactRight}</ContactTextSC>
          </div>
          <TextBodyMedSC style={{ margin: '20px 0px 8px' }}>{translations.cancelationFees}</TextBodyMedSC>
        </ContentContainerSC>
        <ButtonMenuSC>
          <Button
            variant="primary"
            onClick={() => {
              onHandleCloseModal();
            }}
            disabled={disableButton}
            title={'call-to-cancel'}
            isSideBySide={true}
          >
            {translations.buttonText}
          </Button>
        </ButtonMenuSC>
      </BaseModal.Content>
    </BaseModal>
  );
};

export default CallToCancelModal;
