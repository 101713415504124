import styled from 'styled-components';

const ContentContainerSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
`;

const FontAwesomeSC = styled.i`
  display: flex;
  height: 64px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #e50000;
  font-size: 3.5rem;
`;

const ModalTitleSC = styled.h3`
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.333;
  margin: 0;
`;

const TextBodyMedSC = styled.p`
  font-family: 'Mulish', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;

const ButtonMenuSC = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

const FontAwesomeSpinnerSC = styled.i`
  color: #ffffff;
`;

export default {
  ContentContainerSC,
  FontAwesomeSC,
  ModalTitleSC,
  TextBodyMedSC,
  ButtonMenuSC,
  FontAwesomeSpinnerSC,
};
