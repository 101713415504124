import styled, { css } from 'styled-components';
import { mediaMin, mediaMax } from '../../../../shared/style';

const Wrapper = styled.div`
  width: 300px;
  /* 
  ${mediaMax.tablet`
    width: 150px;
  `}; */

  ${mediaMax.phone`
    margin-top: 27px;
    width: 100%;
  `};
`;
const ProjectDetailsCard = styled.div`
  width: 100%;
`;

const CardTitle = styled.h3`
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;

  /* text/default */
  color: #3e4148;
`;

const DetailTitle = styled.p`
  height: 16px;

  /* label / field */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  margin: 24px 0px 4px;

  /* label/field */
  color: #6e727c;
`;

const DetailContent = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  margin: 0px;

  color: #3e4148;

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `};

  ${mediaMin.tablet`
		font-size: 12px;
	`};

  ${mediaMin.desktop`
		font-size: 16px;
	`};
`;

const CancelButtonSC = styled.button`
  border: none;
  outline: none;
  background: none;
  color: #3e4148;
  cursor: pointer;
  padding: 0px;
  margin-top: 24px;
`;

export default {
  Wrapper,
  ProjectDetailsCard,
  CardTitle,
  DetailTitle,
  DetailContent,
  CancelButtonSC,
};
