import React, { useState, useEffect } from 'react';
import Loader from '../../components/Loader';
import queryString from 'query-string';
import _ from 'lodash';

import DetailsComponent from './components/DetailsComponent';
import LaunchProject from './components/LaunchProjectContainer';
import Button from './components/Button';

import Banner from './sections/Banner';
import PreliminaryEstimate from './sections/PreliminaryEstimate';
import TitleSection from './sections/Title';
import ProjectInformation from './sections/ProjectInformation';
import PricingAssumptionsSection from './sections/PricingAssumptions';
import Rebates from './sections/Rebates';
import TotalSection from './sections/TotalSection';

import commonStyles from '../../components/DesignPatternV2.0/commonStyles';
import styledComponents from './styles';

import translationsLocalData from '../../../server/locale/en_US.json';
import { getRequestByGuid } from './helpers/get';
import CancelSection from './components/CancelSection';

const { MainWrapper, QmeritLogo, H4, P_Contact, GlobalTrackerStyles } = commonStyles;

const {
  QmeritHeader,
  InfoWrapper,
  LeftColumn,
  EstimateDivider,
  MobileSection,
  RightColumn,
  Footer,
  FooterMobile,
  FooterDivider,
  FooterContainer,
  ProjectCanceldButtonSC,
  LargeButtonTextColorOverwriteSC,
} = styledComponents;

export default function UpfrontPricePage(props) {
  const [translations, setTranslations] = useState({});
  const [fetchedRequestData, setFetchedRequestData] = useState([]);
  const [fetchedBidStatus, setFetchedBidStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [browserLanguage, setBrowserLanguage] = useState('');
  const [token, setToken] = useState('');
  const [isProjectCanceled, setIsProjectCanceled] = useState(false);
  const [isRadioDeclineCancelModalOpen, setIsRadioDeclineCancelModalOpen] = useState(false);

  //Getting Request Guid parsing token from the URL
  const getRequestGuid = (url) => {
    return queryString.parse(url).token;
  };

  useEffect(() => {
    if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
      setTranslations(window.env.translations);
    } else {
      setTranslations(translationsLocalData);
    }

    fetch('/language', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((data) => setBrowserLanguage(data.browserLanguage));

    let requestGuid = getRequestGuid(props.location.search);
    setToken(requestGuid);

    if (!_.isEmpty(requestGuid)) {
      const fetchAndSetRequest = async () => {
        setIsLoading(true);

        try {
          const guidData = await getRequestByGuid(requestGuid); // EVService api/Estimate

          setFetchedBidStatus(guidData?.status);
          setFetchedRequestData(guidData?.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchAndSetRequest();
    }
  }, []);

  if (!isLoading && fetchedRequestData && fetchedBidStatus == 200) {
    const cultureCode = fetchedRequestData.Request.CultureCode;

    const canceled = isProjectCanceled || fetchedRequestData.Request.CancelledOn;
    const isProjectLaunched = fetchedRequestData.Request.SurveyProjectId !== null;

    return (
      <React.Fragment>
        <GlobalTrackerStyles />
        <QmeritHeader cultureCode={cultureCode} domain={`${window.env.evBlob}`}>
          <QmeritLogo src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-White.png`} />
        </QmeritHeader>
        <MainWrapper>
          {/* Banner */}
          <Banner translations={translations.estimatePage} cultureCode={cultureCode} />
          <InfoWrapper>
            <LeftColumn>
              {/* Title */}
              <TitleSection translations={translations.estimatePage} />
              <EstimateDivider />
              {/* Project Information */}
              <ProjectInformation fetchedRequestData={fetchedRequestData} translations={translations.estimatePage} />
              <EstimateDivider />
              {/* Preliminary Estimate */}
              <PreliminaryEstimate fetchedRequestData={fetchedRequestData} translations={translations.estimatePage} />
              <EstimateDivider />
              {/* Pricing Assumptions */}
              <PricingAssumptionsSection
                translations={translations.estimatePage}
                fetchedRequestData={fetchedRequestData}
                noRebates={
                  _.isEmpty(fetchedRequestData.Request.Rebates) || _.isNil(fetchedRequestData.Request.Rebates)
                    ? true
                    : false
                }
              />
              {/* Rebates */}
              <Rebates fetchedRequestData={fetchedRequestData} />
              {/* Mobile View */}
              <MobileSection>
                <EstimateDivider
                  noRebates={
                    _.isEmpty(fetchedRequestData.Request.Rebates) || _.isNil(fetchedRequestData.Request.Rebates)
                      ? true
                      : false
                  }
                />
                <DetailsComponent
                  fetchedRequestData={fetchedRequestData}
                  browserLanguage={browserLanguage}
                  translations={translations.estimatePage}
                />
              </MobileSection>
              {/* End of Mobile View */}
              {/* Mobile View */}
              {!canceled && !isProjectLaunched && (
                <MobileSection>
                  <EstimateDivider />
                  <CancelSection
                    translations={translations.estimatePage.totalSection.total.cancel}
                    handleOnClick={() => setIsRadioDeclineCancelModalOpen(true)}
                  />
                </MobileSection>
              )}
              {/* End of Mobile View */}
            </LeftColumn>
            <RightColumn>
              {/* Total Section */}
              <TotalSection
                fetchedRequestData={fetchedRequestData}
                browserLanguage={browserLanguage}
                requestId={token}
                translations={translations.estimatePage}
                isCanceled={canceled}
                setIsProjectCanceled={setIsProjectCanceled}
                isRadioDeclineCancelModalOpen={isRadioDeclineCancelModalOpen}
                setIsRadioDeclineCancelModalOpen={setIsRadioDeclineCancelModalOpen}
                isProjectLaunched={isProjectLaunched}
              />
            </RightColumn>
          </InfoWrapper>
          <FooterDivider />
          <FooterContainer>
            <Footer>
              <H4>{translations.estimatePage.footerSection.message}</H4>
              <P_Contact href={`tel: ${fetchedRequestData.Request.Program.CustomerSupportPhone}`}>
                {fetchedRequestData.Request.Program.CustomerSupportPhone}
              </P_Contact>
              <P_Contact href={`mailto: ${fetchedRequestData.Request.Program.CustomerSupportEmail}`}>
                {fetchedRequestData.Request.Program.CustomerSupportEmail}
              </P_Contact>
            </Footer>
            {/* Mobile View */}
            <FooterMobile>
              {canceled ? (
                <ProjectCanceldButtonSC>
                  <Button variant={'grey'} size={'large'}>
                    <LargeButtonTextColorOverwriteSC>
                      {translations.estimatePage.totalSection.total.cancel.projectCanceledButton}
                    </LargeButtonTextColorOverwriteSC>
                  </Button>
                </ProjectCanceldButtonSC>
              ) : (
                <LaunchProject
                  fetchedRequestData={fetchedRequestData}
                  requestId={token}
                  translations={translations.estimatePage}
                />
              )}
            </FooterMobile>
            {/* End of Mobile View */}
          </FooterContainer>
        </MainWrapper>
        <div id={'modal-portal'} />
      </React.Fragment>
    );
  }

  if (!isLoading && [400, 404, 500].includes(fetchedBidStatus)) {
    return <TierUpdateFail translations={translations} />;
  }

  return <Loader color={'#000000'} isLoading={isLoading} />;
}
