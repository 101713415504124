export const radioData = [
  {
    id: 0,
    name: 'declineCancel',
    value: 'I would like an new quote from another installer',
    translationRef: 'declineReason1',
  },
  {
    id: 1,
    name: 'declineCancel',
    value: 'I’ve decided against home charging',
    translationRef: 'cancelReason1',
  },
  {
    id: 2,
    name: 'declineCancel',
    value: 'I’m no longer buying an EV',
    translationRef: 'cancelReason2',
  },
  {
    id: 3,
    name: 'declineCancel',
    value: 'The price is higher than expected',
    translationRef: 'cancelReason3',
  },
  {
    id: 4,
    name: 'declineCancel',
    value: 'I’ve found a lower price',
    translationRef: 'cancelReason4',
  },
  {
    id: 5,
    name: 'declineCancel',
    value: 'I’m just shopping',
    translationRef: 'cancelReason5',
  },
  {
    id: 6,
    name: 'declineCancel',
    value: 'I’m dissatisfied with the installer',
    translationRef: 'cancelReason6',
  },
  {
    id: 7,
    name: 'declineCancel',
    value: 'I’m dissatisfied with Qmerit',
    translationRef: 'cancelReason7',
  },
];
