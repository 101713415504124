import React from 'react';
import StyledComponents from './styles';

const { BaseModalHeaderSC } = StyledComponents;

const ModalHeader = ({ children }) => {
  return <BaseModalHeaderSC>{children}</BaseModalHeaderSC>;
};

export default ModalHeader;
