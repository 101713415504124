import React from 'react';
import StyledComponents from './styles';

const { ButtonSC } = StyledComponents;

export default function Button({ children, variant = 'primary', size = 'medium', disabled, onClick, ...rest }) {
  return (
    <ButtonSC variant={variant} size={size} disabled={disabled} onClick={onClick} {...rest}>
      {children}
    </ButtonSC>
  );
}
